import { handlers as eventMessageHandlers } from '@@event/features/eventmessages/actionqueue.eventmessage';
import * as ExhibitorScans from '@@event/features/exhibitorscan/actionqueue.exhibitorscan';
import { handlers as contactRequestHandlers } from '@@event/features/networking/actionqueue.contactrequest';
import { handlers as discussionsHandlers } from '@@event/features/networking/actionqueue.discussions';
import { handlers as meetingsHandlers } from '@@event/features/networking/actionqueue.meetings';
import { handlers as sessionHandlers } from '@@event/features/sessions/actionqueue.session';
import { handlers as sessionMessageHandlers } from '@@event/features/sessions/actionqueue.sessionmessage';
import { handlers as surveyHandlers } from '@@event/features/surveys/actionqueue.survey';
import { actionQueue } from '@@actionsqueue/index';
import * as Favorites from './actionqueue.favoris';
import * as FavoriteExhibitors from './actionqueue.favoriteexhibitor';
import * as FavoriteOfferings from './actionqueue.favoriteexhibitoroffering';
import * as FavoriteEventThemes from './actionqueue.favoriteeventtheme';
import * as Notifications from './actionqueue.notifications';
import * as Person from './actionqueue.person';

let initialized = false;
export function initActionQueue() {
    if (!initialized) {
        initialized = true;
        actionQueue.registerHandlers(Favorites.handlers);
        actionQueue.registerHandlers(Notifications.handlers);
        actionQueue.registerHandlers(Person.handlers);
        actionQueue.registerHandlers(ExhibitorScans.handlers);
        actionQueue.registerHandlers(eventMessageHandlers);
        actionQueue.registerHandlers(ExhibitorScans.handlers);
        actionQueue.registerHandlers(contactRequestHandlers);
        actionQueue.registerHandlers(discussionsHandlers);
        actionQueue.registerHandlers(contactRequestHandlers);
        actionQueue.registerHandlers(discussionsHandlers);
        actionQueue.registerHandlers(sessionHandlers);
        actionQueue.registerHandlers(sessionMessageHandlers);
        actionQueue.registerHandlers(surveyHandlers);
        actionQueue.registerHandlers(meetingsHandlers);
        actionQueue.registerHandlers(FavoriteExhibitors.handlers);
        actionQueue.registerHandlers(FavoriteOfferings.handlers);
        actionQueue.registerHandlers(FavoriteEventThemes.handlers);
    }
}
