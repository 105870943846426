// import * as ContactRequestAPI from '../../../api/contactrequests';
import { Entities } from '@inwink/entities/entities';
import { guid } from '@inwink/utils/methods/guid';
import type { ActionQueueMessage } from '@inwink/actionqueue';
import { States } from '@@services/services';
import { eventUserBootstrapModule } from '@@routes/appmodules';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers: any = {};

function contactRequestAPI() {
    return import(/* webpackChunkName: "api-contactrequest" */ '../../api/contactrequests');
}

// ------------------------ Ajout d'une demande de contact

interface IAddContactRequestMessage {
    eventId: string;
    tempId: string;
    targetPersonId: string;
    message: string;
    date: string | Date;
    contactRequestPayload: any;
}

export function addContactRequest(
    dispatch,
    getState: () => States.IAppState,
    targetUser: Entities.IPerson,
    message: string,
    contactRequestPayload: any
): Promise<ActionQueueMessage<IAddContactRequestMessage>> {
    const state = getState();
    const payload = <IAddContactRequestMessage>{
        eventId: state.event.eventid,
        tempId: guid(),
        targetPersonId: targetUser.id,
        message: message,
        date: new Date(),
        contactRequestPayload: contactRequestPayload
    };

    const user = state.user.currentUser;
    const existing = user.data.sendedContactRequests.data.find((ct) => ct.targetPersonId === targetUser.id);
    if (existing) {
        existing.status = "Requested";
    } else {
        const ct = <Entities.IContactRequest>{
            creationDate: payload.date,
            targetPersonId: targetUser.id,
            targetPerson: targetUser,
            personId: user.detail.id,
            payload: payload.contactRequestPayload,
            person: <Entities.IPerson>{
                id: user.detail.id,
                firstname: user.detail.firstname,
                lastname: user.detail.lastname,
                photo: null
            },
            status: "Requested",
            text: payload.message,
            $tempId: payload.tempId,
            $transient: new Date()
        };
        user.data.sendedContactRequests.insert(ct);
        user.data.save();
    }
    eventUserBootstrapModule().then((mod) => {
        mod.userSyncActions.dataChanged()(dispatch);
    });

    return Promise.resolve({
        type: "addcontactrequest",
        payload: payload
    });
}

handlers.addcontactrequest = function addcontactrequest(
    message: ActionQueueMessage<IAddContactRequestMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch,
    getState: () => States.IAppState
) {
    if (!message.payload) return Promise.resolve();

    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        context.refreshUserData = true;

        const cleanup = () => {
            const state = getState();
            const userstate = state.user.currentUser;
            userstate.data.sendedContactRequests.removeWhere((ct) => ct.$tempId === message.payload.tempId);
        };
        return contactRequestAPI().then((api) => {
            return api.createContactRequest(
                requestmanager,
                message.payload.targetPersonId,
                message.payload.message,
                message.payload.date,
                message.payload.contactRequestPayload
            ).then((contactrequest) => {
                const state = getState();
                const userstate = state.user.currentUser;
            
                let updatedContactRequest;
                const existingContactRequestIndex = userstate.data.sendedContactRequests.data
                    .findIndex(ct => ct.targetPersonId === contactrequest.targetPersonId);
            
                if (existingContactRequestIndex !== -1) {
                    const existingContactRequest = userstate.data.sendedContactRequests.data[existingContactRequestIndex];
                    updatedContactRequest = {
                        ...contactrequest,
                        person: existingContactRequest.person,
                        targetPerson: existingContactRequest.targetPerson
                    };
                }
                cleanup();
                if (updatedContactRequest) {
                    userstate.data.sendedContactRequests.insert(updatedContactRequest);
                } else {
                    userstate.data.sendedContactRequests.insert(contactrequest);
                }
                if (state?.event?.detail?.configuration?.networking?.dontRequireContactRequest) {
                    eventUserBootstrapModule().then((mod) => {
                        mod.userSyncActions.syncCurrentUser()(dispatch, getState);
                    });
                }
                return userstate.data.save();
            }, (err) => {
                if (err.status && err.status >= 400) {
                    // la demande peut être rejetée côté serveur, à tester en détail, en attendant on clean par sécurité
                    cleanup();
                }
                return Promise.reject(err);
            });
        });
    });
};

// ------------------------ Validation d'une demande de contact

interface IContactRequestMessageValidation {
    eventId: string;
    senderUserId: string;
}

export function acceptContactRequest(
    dispatch,
    getState: () => States.IAppState,
    senderUserId: string
): Promise<ActionQueueMessage<IContactRequestMessageValidation>> {
    const state = getState();
    const userstate = state.user.currentUser;
    const request = userstate.data.receivedContactRequests.data.find((ct) => ct.personId === senderUserId);
    if (request) {
        request.status = "Accepted";
        request.$transient = new Date();
        return userstate.data.save().then(() => {
            const payload = <IContactRequestMessageValidation>{
                eventId: state.event.eventid,
                senderUserId: senderUserId
            };

            eventUserBootstrapModule().then((mod) => {
                mod.userSyncActions.dataChanged()(dispatch);
            });

            return Promise.resolve({
                type: "acceptcontactrequest",
                payload: payload
            });
        });
    }
    return Promise.resolve(null);
}

handlers.acceptcontactrequest = (
    message: ActionQueueMessage<IContactRequestMessageValidation>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return contactRequestAPI().then((api) => {
            return api.acceptContactRequest(requestmanager, message.payload.senderUserId).then(() => {
                context.syncUser = true;
            });
        });
    });
};

// ------------------------ Refus d'une demande de contact

export function declineContactRequest(
    dispatch,
    getState: () => States.IAppState,
    senderUserId: string
): Promise<ActionQueueMessage<IContactRequestMessageValidation>> {
    const state = getState();
    const userstate = state.user.currentUser;
    const request = userstate.data.receivedContactRequests.data.find((ct) => ct.personId === senderUserId);
    if (request) {
        request.status = "Declined";
        request.$transient = new Date();
        return userstate.data.save().then(() => {
            const payload = <IContactRequestMessageValidation>{
                eventId: state.event.eventid,
                senderUserId: senderUserId
            };
            eventUserBootstrapModule().then((mod) => {
                mod.userSyncActions.dataChanged()(dispatch);
            });
            return Promise.resolve({
                type: "declinecontactrequest",
                payload: payload
            });
        });
    }
    return Promise.resolve(null);
}

handlers.declinecontactrequest = function declinecontactrequest(
    message: ActionQueueMessage<IContactRequestMessageValidation>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return contactRequestAPI().then((api) => {
            return api.declineContactRequest(requestmanager, message.payload.senderUserId).then(() => {
                context.syncUser = true;
            });
        });
    });
};
