import type { ActionQueueMessage } from '@inwink/actionqueue';
import type { Entities } from '@inwink/entities/entities';
import { actions as userActions } from '@@services/userservice';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers: any = {};

function sessionPersonAPI() {
    return import('../../api/sessionperson');
}
interface IRegisterMessage {
    eventId: string;
    sessionId: string;
}

export function registerBatch(
    dispatch,
    getState: () => States.IAppState,
    sessionIds: string[]
) : Promise<ActionQueueMessage<IRegisterMessage>[]> {
    const state = getState();
    const datastore = state.user.currentUser.data;

    return new Promise((resolve, reject) => {
        const messages = [];
        if (sessionIds?.length) {
            sessionIds.forEach((sessionId) => {
                const existing = datastore.registeredSessions.data
                    .filter((rs) => rs.sessionId === sessionId && rs.personId === datastore.userId)[0];
                if (existing) {
                    existing.status = "Active";
                    existing.registrationDate = new Date();
                    datastore.registeredSessions.update(existing);
                } else {
                    const sp : Entities.ISessionPerson = {
                        id: undefined,
                        sessionId,
                        personId: datastore.userId,
                        status: 'Active',
                        isRegisted: true,
                        registrationDate: new Date(),
                        hasBookmarked: false,
                        hasParticipated: false
                    };
                    datastore.registeredSessions.insert(sp);
                    messages.push({
                        type: "registertosession",
                        payload: {
                            eventId: state.event.eventid,
                            sessionId
                        }
                    });
                }
            });
            datastore.save().then(() => {
                userActions.dataChanged()(dispatch);
                return messages;
            }).then(resolve, reject);
        }
    });
}

export function register(
    dispatch,
    getState: () => States.IAppState,
    sessionid: string
): Promise<ActionQueueMessage<IRegisterMessage>> {
    const state = getState();
    const datastore = state.user.currentUser.data;

    return new Promise((resolve, reject) => {
        const existing = datastore.registeredSessions.data
            .filter((rs) => rs.sessionId === sessionid && rs.personId === datastore.userId)[0];

        if (existing) {
            existing.status = "Active";
            existing.isRegisted = true;
            existing.registrationDate = new Date();
            datastore.registeredSessions.update(existing);
        } else {
            const sp : Entities.ISessionPerson = {
                id: undefined,
                sessionId: sessionid,
                personId: datastore.userId,
                status: "Active",
                isRegisted: true,
                registrationDate: new Date(),
                hasBookmarked: false,
                hasParticipated: false
            };
            datastore.registeredSessions.insert(sp);
        }

        return datastore.save().then(() => {
            userActions.dataChanged()(dispatch);

            return {
                type: "registertosession",
                payload: {
                    eventId: state.event.eventid,
                    sessionId: sessionid
                }
            };
        }).then(resolve, reject);
    });
}

handlers.registertosession = (
    message: ActionQueueMessage<IRegisterMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return sessionPersonAPI().then((mod) => mod.registerToSession(requestmanager, message.payload.sessionId)).then(() => {
        });
    });
};

export function unregister(
    dispatch,
    getState: () => States.IAppState,
    sessionid: string
): Promise<ActionQueueMessage<IRegisterMessage>> {
    const state = getState();
    const datastore = state.user.currentUser.data;
    return new Promise<any>((resolve, reject) => {
        const existing = datastore.registeredSessions.data
            .filter((rs) => rs.sessionId === sessionid && rs.personId === datastore.userId)[0];

        if (existing) {
            existing.isRegisted = false;
            existing.registrationDate = null;
            existing.hasBookmarked = false;
            existing.bookmarkedDate = null;
            existing.status = "Deleted";
            datastore.registeredSessions.update(existing);
            return datastore.save().then(() => {
                userActions.dataChanged()(dispatch);
            }).then(resolve, reject);
        }
        resolve();
    }).then(() => {
        return {
            type: "unregistertosession",
            payload: {
                eventId: state.event.eventid,
                sessionId: sessionid
            }
        };
    });
}

handlers.unregistertosession = (
    message: ActionQueueMessage<IRegisterMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return sessionPersonAPI().then((mod) => mod.unregisterToSession(requestmanager, message.payload.sessionId)).then(() => {
            // context.syncUser = true;
        });
    });
};

export function bookmark(
    dispatch,
    getState: () => States.IAppState,
    sessionid: string
): Promise<ActionQueueMessage<IRegisterMessage>> {
    const state = getState();
    const datastore = state.user.currentUser.data;

    return new Promise((resolve, reject) => {
        const existing = datastore.registeredSessions.data
            .filter((rs) => rs.sessionId === sessionid && rs.personId === datastore.userId)[0];

        if (existing) {
            existing.status = "Active";
            existing.hasBookmarked = true;
            existing.bookmarkedDate = new Date();
            datastore.registeredSessions.update(existing);
        } else {
            const sp : Entities.ISessionPerson = {
                id: undefined,
                sessionId: sessionid,
                personId: datastore.userId,
                status: "Active",
                isRegisted: false,
                hasBookmarked: true,
                bookmarkedDate: new Date(),
                hasParticipated: false
            };
            datastore.registeredSessions.insert(sp);
        }

        return datastore.save().then(() => {
            userActions.dataChanged()(dispatch);

            return {
                type: "bookmarksession",
                payload: {
                    eventId: state.event.eventid,
                    sessionId: sessionid
                }
            };
        }).then(resolve, reject);
    });
}

handlers.bookmarksession = (
    message: ActionQueueMessage<IRegisterMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return sessionPersonAPI().then((mod) => mod.bookmarkSession(requestmanager, message.payload.sessionId)).then(() => {
        });
    });
};
