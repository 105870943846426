import {Loki, Collection} from '@inwink/inwinkdb';
import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';
import { States } from '../../../../services/services';
import * as Data from '../../../../data';

export interface ISurveysAnswer {
    survey: any;
    scope: string;
    scopeid: string;
    surveyid: string;
    date: string | Date;
}

export interface ISurveysDb {
    db: Loki;
    surveyanswers: Collection<ISurveysAnswer>;
    save(): Promise<any>;
}

export function getSessionSurvey(eventdata: States.IEventDataStore, session: Entities.ISession) {
    const allsurveys = eventdata.surveys.data;
    let surveyDefinition: Entities.ISurvey;

    if (session.surveyId) {
        surveyDefinition = allsurveys.filter((s) => {
            return s.id === session.surveyId;
        })[0];
    }

    if (!surveyDefinition) {
        surveyDefinition = allsurveys.filter((s) => {
            return s.scope === "Session" && s.isDefault;
        })[0];
    }

    if (InWinkPreview && surveyDefinition) {
        return surveyDefinition;
    }
    if (session.timeslots && session.timeslots.length) {
        const dt = moment(session.timeslots[0].startDate);
        if (moment() >= dt) {
            return surveyDefinition;
        }
    }
}

export function getSurveys(eventid, userid) {
    let options;
    const Adapter = Data.getDefaultAdapter();

    const db = new Loki(InWinkEnv + "-" + eventid + '-' + userid + '-surveys.json', options);
    db.initializePersistence({
        adapter: Adapter ? new Adapter() : null
    });
    return db.loadDatabase(null, true).then(() => {
        let col = db.getCollection<ISurveysAnswer>("surveys");
        if (!col) col = db.addCollection<ISurveysAnswer>("surveys");

        return {
            db: db,
            surveyanswers: col,
            save() {
                this.db.saveDatabase();
            }
        };
    });

    // new Promise<ISurveysDb>((dbComplete, dbError) => {
    //     db.loadDatabase({}, (err, data) => {
    //         if (!err) {
    //             let col = db.getCollection<ISurveysAnswer>("surveys");
    //             if (!col) col = db.addCollection<ISurveysAnswer>("surveys");

    //             dbComplete({
    //                 db: db,
    //                 surveyanswers: col,
    //                 save() {
    //                     return new Promise((resolve, reject) => {
    //                         this.db.saveDatabase((err) => {
    //                             if (err) {
    //                                 logger.error("error saving discussion", err);
    //                                 reject(err);
    //                             } else {
    //                                 resolve();
    //                             }
    //                         });
    //                     });
    //                 }
    //             });
    //         } else {
    //             dbError(err);
    //         }
    //     });
    // });
}

export function getSurvey(eventid, userid, surveyid, scope, scopeid) {
    return getSurveys(eventid, userid).then((surveydb) => {
        return surveydb.surveyanswers.data.filter((s) => s.surveyid === surveyid && s.scope === scope && s.scopeid === scopeid);
    });
}
