import type { ActionQueueMessage } from '@inwink/actionqueue';
import { States } from '@@services/services';
import { actionQueue } from '@@actionsqueue/index';
import * as SurveyData from './data.survey';
import { eventRequestManagerActions } from '../../services/eventactions/requestmgr';
import { addSurveyAnswer } from '../../api/surveyanswer';

function tracking() {
    return import("@@services/tracking/actions");
}

export const handlers: any = {};

interface ISurveyAnswerMessage {
    eventId: string;
    personId: string;
    surveyId: string;
    relatedTo: string;
    relatedToKind: string;
    answer: any;
    answerDate: Date
}

export function sendAnswer(
    dispatch,
    getState: () => States.IAppState,
    surveyId: string,
    answer: any,
    relatedToKind?: string,
    relatedTo?: string
): Promise<ActionQueueMessage<ISurveyAnswerMessage>> {
    const state = getState();
    const payload = <ISurveyAnswerMessage>{
        eventId: state.event.eventid,
        personId: state.user && state.user.currentUser && state.user.currentUser.detail ? state.user.currentUser.detail.id : null,
        surveyId: surveyId,
        relatedTo: relatedTo,
        relatedToKind: relatedToKind,
        answer: answer,
        answerDate: new Date()
    };

    return SurveyData.getSurveys(payload.eventId, payload.personId).then((surveysdb) => {
        const existing = surveysdb.surveyanswers.data
            .filter((s) => s.scope === relatedToKind && s.scopeid === relatedTo && s.surveyid === surveyId)[0];

        if (existing) {
            existing.survey = answer;
            existing.date = payload.answerDate;
            surveysdb.surveyanswers.update(existing);
        } else {
            surveysdb.surveyanswers.insert({
                surveyid: surveyId,
                survey: answer,
                scope: relatedToKind,
                scopeid: relatedTo,
                date: payload.answerDate
            });
        }

        return surveysdb.save();
    }).then(() => {
        return {
            type: "surveyanswer",
            payload: payload
        };
    });
}

handlers.surveyanswer = (
    message: ActionQueueMessage<ISurveyAnswerMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return addSurveyAnswer(
            requestmanager,
            message.payload.surveyId,
            message.payload.answer,
            message.payload.answerDate,
            message.payload.relatedToKind,
            message.payload.relatedTo
        ).then(() => {
            context.syncUser = true;
        });
    });
};

export const surveyActions = {
    sendSurvey: (
        surveyId: string,
        answer: any,
        relatedToKind?: string,
        relatedTo?: string
    ) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(
                dispatch, getState, "survey", surveyId, relatedToKind ? relatedToKind + "=" + relatedTo : undefined
            )
        );
        return sendAnswer(
            dispatch,
            getState,
            surveyId,
            answer,
            relatedToKind,
            relatedTo
        ).then((msg) => {
            actionQueue.enqueue(msg, dispatch, getState);
        });
    },
};
