import type { Entities } from '@inwink/entities/entities';
import type { IFileInputManager } from '@inwink/fileutils/fileapi';
import { createTmpFile, finalizeTmpFile, renewTmpAccessFile, tryRenewTmpAccessFile } from '@@api/fileapi';
import type { IEventRequests } from '@@services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from '@@apifront/index';

export function getAvailabilityStatus(eventRequests: IEventRequests, threadid: string): Promise<any> {
    return eventRequests.apiFront.postEventJson("discussionthread/" + threadid + "/availabilitystatus",
        null, defaultPostHeaderForJsonData);
}

export function createDiscussionThread(eventRequests: IEventRequests, personId?: string,
    exhibitorId?: string, meetingId?: string, withOrganizer?: boolean) {
    return eventRequests.apiFront.postEventJson<string>(`discussionthread`, JSON.stringify({
        exhibitorId: exhibitorId,
        personId: personId,
        meetingId: meetingId,
        withOrganizer: withOrganizer
    }), defaultPostHeaderForJsonData);
}

export function addDiscussionThreadMessage(eventRequests: IEventRequests, threadid: string,
    message: string, messagedate: string | Date, timeslotNotAvailableText: string, isFromActiveChat?: boolean,
    fields?: string): Promise<Entities.IDiscussionThreadMessage> {
    return eventRequests.apiFront.postEventJson<Entities.IDiscussionThreadMessage>(`discussionthread/${threadid}/message`,
        JSON.stringify({
            message: message,
            messageDate: messagedate,
            isFromActiveChat,
            payload: fields,
            timeslotNotAvailableText: timeslotNotAvailableText
        }), defaultPostHeaderForJsonData);
}

export function setDiscussionThreadLastRead(eventRequests: IEventRequests, threadid: string, lastReadDate: string | Date) {
    return eventRequests.apiFront.postEventJson<any>(`discussionthread/${threadid}/lastread`, JSON.stringify({
        readDate: lastReadDate
    }), defaultPostHeaderForJsonData);
}

export function getAvailableDates(eventRequests: IEventRequests, threadId: string, capacity?) {
    const url = `discussionthread/${threadId}/meeting/available-timeslots?capacity=${capacity || ''}`;
    return eventRequests.apiFront.getEventJson(url);
}

export function acceptContactInfoRequest(eventRequests: IEventRequests, threadid: string,
    messageid: string, message: string, messagedate: string | Date): Promise<void> {
    return eventRequests.apiFront.postEventJson(`discussionthread/${threadid}/message/${messageid}/contactinfo`, JSON.stringify({
        accept: true,
        message: message,
        messageDate: messagedate
    }), defaultPostHeaderForJsonData);
}

export function declineContactInfoRequest(eventRequests: IEventRequests, threadid: string,
    messageid: string, message: string, messagedate: string | Date): Promise<void> {
    return eventRequests.apiFront.postEventJson(`discussionthread/${threadid}/message/${messageid}/contactinfo`, JSON.stringify({
        accept: false,
        message: message,
        messageDate: messagedate
    }), defaultPostHeaderForJsonData);
}

export function videoChatAccess(eventRequests: IEventRequests, threadid: string, exhibitorId: string,
    message: string, messagedate: string | Date, fields?: string): Promise<any> {
    let url = `discussionthread/${threadid}/videochat`;
    if (exhibitorId) {
        url = url + "?partnerid=" + exhibitorId;
    }
    return eventRequests.apiFront.postEventJson(url, JSON.stringify({
        message: message,
        messageDate: messagedate,
        payload: fields
    }), defaultPostHeaderForJsonData);
}

export function videoChatAccessV2(eventRequests: IEventRequests, threadid: string, exhibitorId: string,
    message: string, messagedate: string | Date, fields?: string): Promise<any> {
    let url = `discussionthread/${threadid}/videochatv2`;
    if (exhibitorId) {
        url = url + "?partnerid=" + exhibitorId;
    }
    return eventRequests.apiFront.postEventJson(url, JSON.stringify({
        message: message,
        messageDate: messagedate,
        payload: fields
    }), defaultPostHeaderForJsonData);
}

export function discussionThreadMessageInputManager(eventRequests: IEventRequests, discussionThreadId: string) {
    const providers = {
        getJson: eventRequests.apiFront.getEventJson,
        postJson: eventRequests.apiFront.postEventJson
    };

    const apiFront = eventRequests?.apiFront as any;

    const context = {
        ...apiFront,
        postJson: apiFront?.postJson,
        post: apiFront?.post,
        _requestPayload: apiFront?._requestPayload,
        _send: apiFront?._send,
        _getApiOptions: apiFront?._getApiOptions,
        _merge: apiFront?._merge,
        _processJsonResponse: apiFront?._processJsonResponse,
    };

    const res: IFileInputManager = {
        createTmpFile(model: {
            entity: string,
            field: string,
            type: string,
            name: string
        }) {
            return createTmpFile(providers, model, context);
        },

        renewTmpAccessFile(model: {
            blobName: string,
            entity: string,
            field: string,
            type: string,
            name: string
        }) {
            return renewTmpAccessFile(providers, model);
        },

        tryRenewTmpAccessFile(model: {
            sas: string,
            blobName: string,
            entity: string,
            field: string,
            type: string,
            name: string
        }) {
            return tryRenewTmpAccessFile(providers, model);
        },

        getFileAccess(entity: string, field: string, entityId: string) {
            return eventRequests.apiFront
                .getEventJson<any>(`discussionthread/${discussionThreadId}/message/${entityId}/fileaccess`, null);
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        copyToTmp(eventId: string, entityName: string, entityId: string, fieldKey: string) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ message: "not supported" });
        },

        finalizeTmpFile(entity: string, blobModel: {
            sas?: string;
            blobName?: string;
        }) {
            return finalizeTmpFile(providers, blobModel);
        }
    };

    return res;
}
