import type { ActionQueueMessage } from '@inwink/actionqueue';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

function sessionMessageApi() {
    return import('../../api/sessionmessage');
}

export const handlers: any = {};
interface ISendSessionMessage {
    eventId: string;
    sessionId: string;
    messageKind: number;
    message: string;
    date: any;
    replyToId: string;
}

export function sendSessionMessage(
    dispatch,
    getState: () => States.IAppState,
    sessionId: string,
    messageKind: number,
    date: any,
    message: string,
    replyTo: string
): Promise<ActionQueueMessage<ISendSessionMessage>> {
    const state = getState();
    return Promise.resolve({
        type: "sendSessionMessage",
        payload: {
            eventId: state.event.eventid,
            sessionId: sessionId,
            messageKind: messageKind,
            date: date,
            message: message,
            replyToId: replyTo
        }
    });
}

handlers.sendSessionMessage = (
    message: ActionQueueMessage<ISendSessionMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch,
    getState:() => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return sessionMessageApi().then((mod) => {
            return mod.createSessionMessage(
                requestmanager,
                message.payload.sessionId,
                message.payload.messageKind,
                message.payload.message,
                message.payload.date,
                message.payload.replyToId
            ).then(() => {
                // context.syncEvent = true;
            });
        });
    });
};
