import { States } from "@@services/services";
import { getAccessManager } from '@@services/apiaccessprovider';

export const eventRequestManagerActions = {
    getEventRequestManager(eventid: string) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (state.event.eventid === eventid) {
                return Promise.resolve(state.event.requestManagers);
            }

            const requestmgr = getAccessManager().then((mgr) => mgr.getEventRequests(eventid));
            return Promise.resolve(requestmgr);
        };
    }
};
