import type { ActionQueueMessage } from '@inwink/actionqueue';
import type { Entities } from '@inwink/entities/entities';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';
import { actionQueue } from '@@actionsqueue/index';
import { eventUserActionsModule } from '@@routes/appmodules';

export const handlers: any = {};

// ------------------------ Autoriser networking
function notifAPI() {
    return import("@@event/api/notification");
}
function networkingAPI() {
    return import("@@event/api/networking");
}
function personAPI() {
    return import("@@event/api/person");
}

function tracking() {
    return import("@@services/tracking/actions");
}
interface IAllowNetworkingMessage {
    eventId: string;
    allowNetworking: boolean;
}

function userDataChanged(dispatch) {
    eventUserActionsModule().then((mod) => mod.userDataActions.dataChanged(null)(dispatch));
}

export function allowNetworking(
    dispatch,
    getState: () => States.IAppState,
    isAllowNetworking: boolean
): Promise<ActionQueueMessage<IAllowNetworkingMessage>> {
    tracking().then(
        (mod) => mod.tracker.trackAction(
            dispatch,
            getState,
            'profile',
            'allownetworking',
            isAllowNetworking.toString()
        )
    );
    const state = getState();

    return new Promise((resolve, reject) => {
        const detailstore = state.user.currentUser.detail;
        detailstore.allowNetworking = isAllowNetworking;

        if (state.user.currentUser.data.userDetail.data.length) {
            const detail = state.user.currentUser.data.userDetail.data[0];
            detail.allowNetworking = isAllowNetworking;
            state.user.currentUser.data.userDetail.update(detail);
        }

        state.user.currentUser.data.save().then(resolve, reject);
    }).then(() => {
        userDataChanged(dispatch);
        return {
            type: 'allownetworking',
            payload: {
                eventId: state.event.eventid,
                allowNetworking: isAllowNetworking,
            },
        };
    });
}

handlers.allownetworking = (
    message: ActionQueueMessage<IAllowNetworkingMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch,
    getState: () => States.IAppState
) => {
    context.syncUser = true;
    context.syncUserRecommandations = true;
    return eventRequestManagerActions
        .getEventRequestManager(message.payload.eventId)(dispatch, getState)
        .then((requestmanager) => {
            return networkingAPI().then((mod) => mod.updateAllowNetworking(
                requestmanager,
                message.payload.allowNetworking
            ));
        });
};

// ------------------------ Préférences de networking

interface INetworkingPreferencesMessage {
    eventId: string;
    prefs: Entities.IPersonNetworkingPreferences;
    oldPrefs?: Entities.IPersonNetworkingPreferences;
}

export function networkingPreferences(
    dispatch,
    getState: () => States.IAppState,
    prefs: Entities.IPersonNetworkingPreferences
): Promise<ActionQueueMessage<INetworkingPreferencesMessage>> {
    tracking().then(
        (mod) => mod.tracker.trackAction(dispatch, getState, 'profile', 'networkingpreferences')
    );
    const state = getState();
    const oldPrefs = Object.assign({}, state.user.currentUser.detail.preferences);
    return new Promise((resolve, reject) => {
        const detailstore = state.user.currentUser.detail;
        detailstore.preferences = prefs;
        userDataChanged(dispatch);
        if (state.user.currentUser.data.userDetail.data.length) {
            const detail = state.user.currentUser.data.userDetail.data[0];
            detail.preferences = prefs;
            state.user.currentUser.data.userDetail.update(detail);
        }

        state.user.currentUser.data.save().then(resolve, reject);
    })
        .then(() => {
            userDataChanged(dispatch);
            return {
                type: 'networkingpreferences',
                payload: {
                    eventId: state.event.eventid,
                    prefs,
                    oldPrefs: oldPrefs
                },
            };
        })
        .then((msg) => {
            actionQueue.enqueue(msg, dispatch, getState);
            return msg;
        });
}

handlers.networkingpreferences = (
    message: ActionQueueMessage<INetworkingPreferencesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    context.syncUser = true;
    context.syncUserRecommandations = true;
    return eventRequestManagerActions
        .getEventRequestManager(message.payload.eventId)(dispatch, getState)
        .then((requestmanager) => {
            return networkingAPI().then((mod) => mod.updateNetworkingPreferences(
                requestmanager,
                message.payload.prefs
            ));
        });
};

// ------------------------ Préférences de notifications

interface INotificationsPreferencesMessage {
    eventId: string;
    prefs: Entities.IAppUserNotificationPreferences;
}

export function notificationsPreferences(
    dispatch,
    getState: () => States.IAppState,
    allowMailAlerts: boolean,
    allowNotifications: boolean,
    notifyOnContactRequest: boolean,
    notifyOnMessage: boolean,
    allowMailWhenInvitedHasAttended: boolean,
    allowMailWhenInvitedIsRegistered: boolean
): Promise<ActionQueueMessage<INotificationsPreferencesMessage>> {
    const state = getState();
    const currentstate = getState();
    const detailstore = currentstate.user.currentUser.detail;
    const notifPrefs = {
        allowNotifications,
        notifyOnContactRequest,
        notifyOnMessage,
        allowMailAlerts,
        allowMailWhenInvitedHasAttended,
        allowMailWhenInvitedIsRegistered,
    };

    return new Promise((resolve, reject) => {
        detailstore.notificationPreferences = notifPrefs;
        userDataChanged(dispatch);
        if (state.user.currentUser.data.userDetail.data.length) {
            const detail = state.user.currentUser.data.userDetail.data[0];
            detail.notificationPreferences = notifPrefs;
            state.user.currentUser.data.userDetail.update(detail);
            return state.user.currentUser.data.save().then(resolve, reject);
        }
        resolve();
    }).then(() => {
        return {
            type: 'notificationspreferences',
            payload: {
                eventId: state.event.eventid,
                prefs: notifPrefs,
            },
        };
    });
}

handlers.notificationspreferences = (
    message: ActionQueueMessage<INotificationsPreferencesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions
        .getEventRequestManager(message.payload.eventId)(dispatch, getState)
        .then((requestmanager) => {
            return notifAPI().then((mod) => mod.updateNotificationPreferences(
                requestmanager,
                message.payload.prefs
            ));
        });
};

// ------------------------ Register device

interface IRegisterDeviceMessage {
    eventId: string;
    deviceId: string;
    deviceType: string;
    notificationChannel: string;
    notificationChannelDate: string | Date;
}

export function registerDevice(
    dispatch,
    getState: () => States.IAppState,
    uniqueid: string,
    devicetype: string,
    channel: string,
    date: string | Date
): Promise<ActionQueueMessage<IRegisterDeviceMessage>> {
    const state = getState();
    return Promise.resolve({
        type: 'registerdevice',
        payload: {
            eventId: state.event.eventid,
            deviceId: uniqueid,
            deviceType: devicetype,
            notificationChannel: channel,
            notificationChannelDate: date,
        },
    });
}

handlers.registerdevice = (
    message: ActionQueueMessage<IRegisterDeviceMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions
        .getEventRequestManager(message.payload.eventId)(dispatch, getState)
        .then((requestmanager) => {
            return personAPI().then((mod) => mod.registerPersonDevice(
                requestmanager,
                message.payload.deviceId,
                message.payload.deviceType,
                message.payload.notificationChannel,
                message.payload.notificationChannelDate
            ));
        });
};

interface IRemoveFromRecommandationsMessage {
    eventId: string;
    targetUserId: string;
}

export function removeFromRecommandations(
    dispatch,
    getState: () => States.IAppState,
    targetUserId: string
): Promise<ActionQueueMessage<IRemoveFromRecommandationsMessage>> {
    const state = getState();
    state.user.currentUser.data.contactRecommandations.removeWhere(
        (cr) => cr.id === targetUserId
    );
    state.user.currentUser.data.sendedContactRequests.insert({
        person: { id: state.user.currentUser.detail.id } as any,
        personId: state.user.currentUser.detail.id,
        status: 'Exclude',
        targetPerson: { id: targetUserId } as any,
        targetPersonId: targetUserId,
        creationDate: new Date(),
        text: null,
    });

    return state.user.currentUser.data.save().then(() => {
        userDataChanged(dispatch);
        return {
            type: 'removefromrecommandations',
            payload: {
                eventId: state.event.eventid,
                targetUserId,
            },
        };
    });
}

handlers.removefromrecommandations = (
    message: ActionQueueMessage<IRemoveFromRecommandationsMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions
        .getEventRequestManager(message.payload.eventId)(dispatch, getState)
        .then((requestmanager) => {
            return networkingAPI().then((mod) => mod.excludeContactRecommandation(
                requestmanager,
                message.payload.targetUserId
            ));
        });
};
