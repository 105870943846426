import type { ActionQueueMessage } from '@inwink/actionqueue';
import { actions as userActions } from '@@services/userservice';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers:any = {};

interface IAddExhibitorToFavoritesMessage {
    eventId: string;
    exhibitorId: string;
}

function favExhibitorAPI() {
    return import("@@event/api/personfavoriteexhibitor");
}

export function addExhibitorsToFavorites(
    dispatch,
    getState: () => States.IAppState,
    exhibitorIds: string[]
): Promise<ActionQueueMessage<IAddExhibitorToFavoritesMessage>[]> {
    const state = getState();
    const datastore = state.user.currentUser.data;

    if (exhibitorIds.length) {
        const messages = [];
        exhibitorIds.forEach((exhibitorId) => {
            const existing = datastore.favoriteExhibitors.data.filter((fe) => fe.exhibitorId === exhibitorId)[0];
            if (existing) {
                existing.status = 'Active';
                datastore.favoriteExhibitors.update(existing);
            } else {
                const newitem: States.IUserFavoriteExhibitor = {
                    id: exhibitorId,
                    exhibitorId,
                    lastUpdateDate: new Date() as any,
                    status: 'Active'
                };
                messages.push({
                    type: 'addtofavoriteexhibitor',
                    correlationid: 'favoriteexhibitor-' + exhibitorId,
                    payload: {
                        eventId: state.event.eventid,
                        exhibitorId
                    }
                });
                state.user.currentUser.data.favoriteExhibitors.insert(newitem);
            }
        });
        userActions.dataChanged()(dispatch);
        return state.user.currentUser.data.save().then(() => {
            return messages;
        });
    }
}

export function addExhibitorToFavorites(
    dispatch,
    getState: () => States.IAppState,
    exhibitorId: string
): Promise<ActionQueueMessage<IAddExhibitorToFavoritesMessage>> {
    const state = getState();

    const payload = {
        type: 'addtofavoriteexhibitor',
        correlationid: 'favoriteexhibitor-' + exhibitorId,
        payload: {
            eventId: state.event.eventid,
            exhibitorId
        }
    };

    const existing = state.user.currentUser.data.favoriteExhibitors.data.filter((fe) => fe.exhibitorId === exhibitorId)[0];
    if (existing) {
        existing.status = 'Active';
        state.user.currentUser.data.favoriteExhibitors.update(existing);
    } else {
        const newitem: States.IUserFavoriteExhibitor = {
            id: exhibitorId,
            exhibitorId,
            lastUpdateDate: new Date() as any,
            status: 'Active'
        };
        state.user.currentUser.data.favoriteExhibitors.insert(newitem);
    }

    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.addtofavoriteexhibitor = function addtofavoriteexhibitor(
    message: ActionQueueMessage<IAddExhibitorToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return favExhibitorAPI().then((mod) => mod.addPersonFavoriteExhibitor(
            requestmanager, message.payload.exhibitorId
        )).then(() => {
            context.syncUser = true;
        });
    });
};

export function removeExhibitorFromFavorites(
    dispatch,
    getState: () => States.IAppState,
    exhibitorId: string
): Promise<ActionQueueMessage<IAddExhibitorToFavoritesMessage>> {
    const state = getState();

    const payload = {
        type: 'removeexhibitorfromfavorites',
        correlationid: 'favoriteexhibitor-' + exhibitorId,
        payload: {
            eventId: state.event.eventid,
            exhibitorId
        }
    };

    const existing = state.user.currentUser.data.favoriteExhibitors.data.filter((fe) => fe.exhibitorId === exhibitorId)[0];
    if (existing) {
        state.user.currentUser.data.favoriteExhibitors.remove(existing);
    }
    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.removeexhibitorfromfavorites = function removeexhibitorfromfavorites(
    message: ActionQueueMessage<IAddExhibitorToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return favExhibitorAPI().then((mod) => mod.deletePersonFavoriteExhibitor(
            requestmanager, message.payload.exhibitorId
        )).then(() => {
            context.syncUser = true;
        });
    });
};

interface IAllowSharingInfoExhibitorMessage {
    eventId: string;
    exhibitorId: string;
}

export function allowSharingInfoExhibitor(
    dispatch,
    getState: () => States.IAppState,
    exhibitorId: string
): Promise<ActionQueueMessage<IAllowSharingInfoExhibitorMessage>> {
    const state = getState();

    const payload = {
        type: 'allowsharinginfoexhibitor',
        correlationid: 'favoriteexhibitor-' + exhibitorId,
        payload: {
            eventId: state.event.eventid,
            exhibitorId
        }
    };

    const existing = state.user.currentUser.data.favoriteExhibitors.data.filter((fe) => fe.exhibitorId === exhibitorId)[0];
    if (existing) {
        existing.status = 'Active';
        existing.allowSharingInfo = true;
        state.user.currentUser.data.favoriteExhibitors.update(existing);
    } else {
        const newitem: States.IUserFavoriteExhibitor = {
            id: exhibitorId,
            exhibitorId,
            lastUpdateDate: new Date() as any,
            status: 'Active',
            allowSharingInfo: true
        };
        state.user.currentUser.data.favoriteExhibitors.insert(newitem);
    }

    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.allowsharinginfoexhibitor = function allowsharinginfoexhibitor(
    message: ActionQueueMessage<IAllowSharingInfoExhibitorMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return favExhibitorAPI().then((mod) => mod.allowShareInfoWithExhibitor(
            requestmanager, message.payload.exhibitorId
        )).then(() => {
            context.syncUser = true;
        });
    });
};
