import type { ActionQueueMessage } from '@inwink/actionqueue';
import { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';
import { addEventMessage } from '../../api/eventmessage';

export const handlers: any = {};

interface ISendEventMessage {
    eventId: string;
    message: string;
    picture: string;
    replyToId?: string;
    additionalProperties?: any;
}

export function sendEventMessage(
    dispatch,
    getState: () => States.IAppState,
    message: string,
    picture: string,
    replyToId?: string,
    additionalProperties?: any
): Promise<ActionQueueMessage<ISendEventMessage>> {
    const state = getState();
    return Promise.resolve({
        type: "sendEventMessage",
        payload: {
            eventId: state.event.eventid,
            message: message,
            picture: picture,
            replyToId: replyToId,
            additionalProperties: additionalProperties
        }
    });
}

handlers.sendEventMessage = (
    message: ActionQueueMessage<ISendEventMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        const m = { text: message.payload.message, picture: message.payload.picture, ...message.payload.additionalProperties };
        return addEventMessage(requestmanager, m).then(() => {
            context.syncEvent = true;
        });
    });
};
