import type { ActionQueueMessage } from '@inwink/actionqueue';
import { actions as userActions } from '@@services/userservice';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers: any = {};

interface IAddToFavoritesMessage {
    eventId: string;
    entityId: string;
    entityKind: string;
}

function getDataStore(entityKind) {
    let datastoreCollection = "";
    if (entityKind === "Session") {
        datastoreCollection = "favoriteSessions";
    } else if (entityKind === "Exhibitor") {
        datastoreCollection = "favoriteExhibitors";
    } else if (entityKind === "EventMessage") {
        datastoreCollection = "favoriteEventMessages";
    } else if (entityKind === "SessionMessage") {
        datastoreCollection = "favoriteSessionMessages";
    }
    return datastoreCollection;
}

function sessionMessageApi() {
    return import('@@event/api/sessionmessage');
}

function eventMessageApi() {
    return import('@@event/api/eventmessage');
}

export function addToFavorites(
    dispatch,
    getState: () => States.IAppState,
    entityId: string,
    entityKind: string
): Promise<ActionQueueMessage<IAddToFavoritesMessage>> {
    const state = getState();

    const datastore = state.user.currentUser.data;
    const datastoreCollection = getDataStore(entityKind);

    return new Promise((resolve, reject) => {
        if (datastoreCollection) {
            datastore[datastoreCollection].insert({
                entityId: entityId,
                entityKind: entityKind,
                personId: datastore.userId
            });

            return datastore.save().then(() => {
                userActions.dataChanged()(dispatch);

                return {
                    type: "addtofavorites",
                    correlationid: "favorite-" + entityKind + "-" + entityId,
                    payload: {
                        eventId: state.event.eventid,
                        entityId: entityId,
                        entityKind: entityKind
                    }
                };
            }).then(resolve, reject);
        }
    });
}

handlers.addtofavorites = (
    message: ActionQueueMessage<IAddToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        switch (message.payload.entityKind) {
            case "SessionMessage":
                return sessionMessageApi().then((mod) => mod.addSessionMessageToFavorite(
                    requestmanager,
                    message.payload.entityId
                ));
            case "EventMessage":
                return eventMessageApi().then((mod) => mod.addEventMessageToFavorite(requestmanager, message.payload.entityId));
            default:
                return Promise.reject(new Error("not implemented entity"));
        }
    });
};

export function removeFromFavorites(
    dispatch,
    getState: () => States.IAppState,
    entityId: string,
    entityKind: string
): Promise<ActionQueueMessage<IAddToFavoritesMessage>> {
    const state = getState();
    const datastore = state.user.currentUser.data;
    const datastoreCollection = getDataStore(entityKind);

    return new Promise<any>((resolve, reject) => {
        if (datastoreCollection) {
            const existing = datastore[datastoreCollection].findOne({ entityId: entityId });
            datastore[datastoreCollection].remove(existing);
            return datastore.save().then(() => {
                userActions.dataChanged()(dispatch);
            }).then(resolve, reject);
        }
    }).then(() => {
        userActions.dataChanged()(dispatch);
        return {
            type: "removefromfavorites",
            correlationid: "favorite-" + entityKind + "-" + entityId,
            payload: {
                eventId: state.event.eventid,
                entityId: entityId,
                entityKind: entityKind
            }
        };
    });
}

handlers.removefromfavorites = (
    message: ActionQueueMessage<IAddToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        switch (message.payload.entityKind) {
            case "SessionMessage":
                return sessionMessageApi().then((mod) => mod.removeSessionMessageFromFavorite(
                    requestmanager, message.payload.entityId
                ));
            case "EventMessage":
                return eventMessageApi().then((mod) => mod.removeEventMessageFromFavorite(
                    requestmanager,
                    message.payload.entityId
                ));
            default:
                return Promise.reject(new Error("not implemented entity"));
        }
    });
};
