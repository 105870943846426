import type { ActionQueueMessage } from '@inwink/actionqueue';
import { actions as userActions } from '@@services/userservice';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers:any = {};

interface IAddEventThemeToFavoritesMessage {
    eventId: string;
    eventThemeId: string;
}

function favEventThemeAPI() {
    return import("@@event/api/personfavoriteeventtheme");
}

export function addEventThemesToFavorites(
    dispatch,
    getState: () => States.IAppState,
    eventThemeIds: string[]
): Promise<ActionQueueMessage<IAddEventThemeToFavoritesMessage>[]> {
    const state = getState();
    const datastore = state.user.currentUser.data;

    if (eventThemeIds.length) {
        const messages = [];
        eventThemeIds.forEach((eventThemeId) => {
            const existing = datastore.favoriteEventThemes.data.filter((fe) => fe.eventThemeId === eventThemeId)[0];
            if (existing) {
                existing.status = 'Active';
                datastore.favoriteEventThemes.update(existing);
            } else {
                const newitem: States.IUserFavoriteEventTheme = {
                    id: eventThemeId,
                    eventThemeId,
                    lastUpdateDate: new Date() as any,
                    status: 'Active'
                };
                messages.push({
                    type: 'addtofavoriteeventtheme',
                    correlationid: 'favoriteeventtheme-' + eventThemeId,
                    payload: {
                        eventId: state.event.eventid,
                        eventThemeId
                    }
                });
                state.user.currentUser.data.favoriteEventThemes.insert(newitem);
            }
        });
        userActions.dataChanged()(dispatch);
        return state.user.currentUser.data.save().then(() => {
            return messages;
        });
    }
}

export function addEventThemeToFavorites(
    dispatch,
    getState: () => States.IAppState,
    eventThemeId: string
): Promise<ActionQueueMessage<IAddEventThemeToFavoritesMessage>> {
    const state = getState();

    const payload = {
        type: 'addtofavoriteeventtheme',
        correlationid: 'favoriteeventtheme-' + eventThemeId,
        payload: {
            eventId: state.event.eventid,
            eventThemeId
        }
    };

    const existing = state.user.currentUser.data.favoriteEventThemes.data.filter((fe) => fe.eventThemeId === eventThemeId)[0];
    if (existing) {
        existing.status = 'Active';
        state.user.currentUser.data.favoriteEventThemes.update(existing);
    } else {
        const newitem: States.IUserFavoriteEventTheme = {
            id: eventThemeId,
            eventThemeId,
            lastUpdateDate: new Date() as any,
            status: 'Active'
        };
        state.user.currentUser.data.favoriteEventThemes.insert(newitem);
    }

    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.addtofavoriteeventtheme = function addtofavoriteeventtheme(
    message: ActionQueueMessage<IAddEventThemeToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return favEventThemeAPI().then((mod) => mod.addPersonFavoriteEventTheme(
            requestmanager, message.payload.eventThemeId
        )).then(() => {
            context.syncUser = true;
        });
    });
};

export function removeEventThemeFromFavorites(
    dispatch,
    getState: () => States.IAppState,
    eventThemeId: string
): Promise<ActionQueueMessage<IAddEventThemeToFavoritesMessage>> {
    const state = getState();

    const payload = {
        type: 'removeeventthemefromfavorites',
        correlationid: 'favoriteeventtheme-' + eventThemeId,
        payload: {
            eventId: state.event.eventid,
            eventThemeId
        }
    };

    const existing = state.user.currentUser.data.favoriteEventThemes.data.filter((fe) => fe.eventThemeId === eventThemeId)[0];
    if (existing) {
        state.user.currentUser.data.favoriteEventThemes.remove(existing);
    }
    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.removeeventthemefromfavorites = function removeeventthemefromfavorites(
    message: ActionQueueMessage<IAddEventThemeToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return favEventThemeAPI().then((mod) => mod.deletePersonFavoriteEventTheme(
            requestmanager, message.payload.eventThemeId
        )).then(() => {
            context.syncUser = true;
        });
    });
};
