import type { ActionQueueMessage } from '@inwink/actionqueue';
import { actions as userActions } from '@@services/userservice';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers:any = {};

function offeringAPI() {
    return import('@@event/api/personfavoriteexhibitoroffering');
}

interface IAddExhibitorOfferingToFavoritesMessage {
    eventId: string;
    exhibitorOfferingId: string;
}

export function addExhibitorOfferingToFavorites(
    dispatch,
    getState: () => States.IAppState,
    exhibitorOfferingId: string
): Promise<ActionQueueMessage<IAddExhibitorOfferingToFavoritesMessage>> {
    const state = getState();

    const payload = {
        type: 'addtofavoriteexhibitoroffering',
        correlationid: 'favoriteexhibitoroffering-' + exhibitorOfferingId,
        payload: {
            eventId: state.event.eventid,
            exhibitorOfferingId
        }
    };

    const existing = state.user.currentUser.data.favoriteExhibitorOfferings.data
        .filter((fe) => fe.exhibitorOfferingId === exhibitorOfferingId)[0];
    if (existing) {
        existing.status = 'Active';
        state.user.currentUser.data.favoriteExhibitorOfferings.update(existing);
    } else {
        const newitem: States.IUserFavoriteExhibitorOffering = {
            id: exhibitorOfferingId,
            exhibitorOfferingId,
            lastUpdateDate: new Date() as any,
            status: 'Active'
        };
        state.user.currentUser.data.favoriteExhibitorOfferings.insert(newitem);
    }

    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.addtofavoriteexhibitoroffering = function addtofavoriteexhibitoroffering(
    message: ActionQueueMessage<IAddExhibitorOfferingToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return offeringAPI().then((mod) => mod.addPersonFavoriteExhibitorOffering(
            requestmanager, message.payload.exhibitorOfferingId
        )).then(() => {
            context.syncUser = true;
        });
    });
};

export function removeExhibitorOfferingFromFavorites(
    dispatch,
    getState: () => States.IAppState,
    exhibitorOfferingId: string
): Promise<ActionQueueMessage<IAddExhibitorOfferingToFavoritesMessage>> {
    const state = getState();

    const payload = {
        type: 'removeexhibitorofferingfromfavorites',
        correlationid: 'favoriteexhibitoroffering-' + exhibitorOfferingId,
        payload: {
            eventId: state.event.eventid,
            exhibitorOfferingId
        }
    };

    const existing = state.user.currentUser.data.favoriteExhibitorOfferings.data
        .filter((fe) => fe.exhibitorOfferingId === exhibitorOfferingId)[0];
    if (existing) {
        state.user.currentUser.data.favoriteExhibitorOfferings.remove(existing);
    }
    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.removeexhibitorofferingfromfavorites = function removeexhibitorofferingfromfavorites(
    message: ActionQueueMessage<IAddExhibitorOfferingToFavoritesMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return offeringAPI().then((mod) => mod.deletePersonFavoriteExhibitorOffering(
            requestmanager, message.payload.exhibitorOfferingId
        )).then(() => {
            context.syncUser = true;
        });
    });
};

interface IAllowSharingInfoExhibitorOfferingMessage {
    eventId: string;
    exhibitorOfferingId: string;
}

export function allowSharingInfoExhibitorOffering(
    dispatch,
    getState: () => States.IAppState,
    exhibitorOfferingId: string
): Promise<ActionQueueMessage<IAllowSharingInfoExhibitorOfferingMessage>> {
    const state = getState();

    const payload = {
        type: 'allowsharinginfoexhibitoroffering',
        correlationid: 'favoriteexhibitoroffering-' + exhibitorOfferingId,
        payload: {
            eventId: state.event.eventid,
            exhibitorOfferingId
        }
    };

    const existing = state.user.currentUser.data.favoriteExhibitorOfferings.data
        .filter((fe) => fe.exhibitorOfferingId === exhibitorOfferingId)[0];
    if (existing) {
        existing.status = 'Active';
        existing.allowSharingInfo = true;
        state.user.currentUser.data.favoriteExhibitorOfferings.update(existing);
    } else {
        const newitem: States.IUserFavoriteExhibitorOffering = {
            id: exhibitorOfferingId,
            exhibitorOfferingId,
            lastUpdateDate: new Date() as any,
            status: 'Active',
            allowSharingInfo: true
        };
        state.user.currentUser.data.favoriteExhibitorOfferings.insert(newitem);
    }

    userActions.dataChanged()(dispatch);
    return state.user.currentUser.data.save().then(() => {
        return payload;
    });
}

handlers.allowsharinginfoexhibitoroffering = function allowsharinginfoexhibitoroffering(
    message: ActionQueueMessage<IAllowSharingInfoExhibitorOfferingMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return offeringAPI().then((mod) => mod.allowShareInfoWithExhibitorOffering(
            requestmanager, message.payload.exhibitorOfferingId
        )).then(() => {
            context.syncUser = true;
        });
    });
};

export function addExhibitorOfferingsToFavorites(
    dispatch,
    getState: () => States.IAppState,
    exhibitorOfferingIds: string[]
): Promise<ActionQueueMessage<IAddExhibitorOfferingToFavoritesMessage>[]> {
    const state = getState();
    const datastore = state.user.currentUser.data;

    if (exhibitorOfferingIds.length) {
        const messages = [];
        exhibitorOfferingIds.forEach((exhibitorOfferingId) => {
            const existing = datastore.favoriteExhibitorOfferings.data
                .filter((feo) => feo.exhibitorOfferingId === exhibitorOfferingId)[0];
            if (existing) {
                existing.status = 'Active';
                datastore.favoriteExhibitorOfferings.update(existing);
            } else {
                const newitem: States.IUserFavoriteExhibitorOffering = {
                    id: exhibitorOfferingId,
                    exhibitorOfferingId,
                    lastUpdateDate: new Date() as any,
                    status: 'Active'
                };
                messages.push({
                    type: 'addtofavoriteexhibitoroffering',
                    correlationid: 'favoriteexhibitoroffering-' + exhibitorOfferingId,
                    payload: {
                        eventId: state.event.eventid,
                        exhibitorOfferingId
                    }
                });
                state.user.currentUser.data.favoriteExhibitorOfferings.insert(newitem);
            }
        });
        userActions.dataChanged()(dispatch);
        return state.user.currentUser.data.save().then(() => {
            return messages;
        });
    }
}
