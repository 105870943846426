// import { addScan, updateScan } from '../../../api/exhibitorscans';
import type { ActionQueueMessage } from '@inwink/actionqueue';
import type { Entities } from '@inwink/entities/entities';
import type { States } from '@@services/services';
import { actionQueue } from '@@actionsqueue/index';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers: any = {};

function exhibitorAPI() {
    return import('../../api/exhibitor.scan');
}

function exhibitorScanAPI() {
    return import('@@partnerworkspace/api/exhibitorscan');
}

function tracking() {
    return import("@@services/tracking/actions");
}

interface IExhibitorScanMessage {
    eventId: string;
    exhibitorId: string;
    badgeId: string;
    data: string;
    scanDate: Date
}

export function sendScan(
    dispatch,
    getState: () => States.IAppState,
    exhibitorId: string,
    badgeId: string,
    data: any
): Promise<ActionQueueMessage<IExhibitorScanMessage>> {
    const state = getState();
    const payload = <IExhibitorScanMessage>{
        eventId: state.event.eventid,
        exhibitorId: exhibitorId,
        badgeId: badgeId,
        data: data,
        scanDate: new Date()
    };

    return Promise.resolve({
        type: "exhibitorscan",
        correlationid: "exhibitorscan-" + exhibitorId + "-" + badgeId,
        payload: payload
    });
}

interface IExhibitorScanTemplateMessage {
    eventid: string;
    exhibitorid: string;
    template: Entities.IEntityTemplate;
}
export function setExhibitorScanTemplate(
    dispatch,
    getState: () => States.IAppState,
    eventid: string,
    exhibitorid: string,
    data: Entities.IEntityTemplate
): Promise<ActionQueueMessage<IExhibitorScanTemplateMessage>> {
    const payload: IExhibitorScanTemplateMessage = {
        eventid,
        exhibitorid,
        template: data
    };

    const state = getState();
    const existing = state.event.data.fieldtemplates.data.find((t) => t.entityName === `ExhibitorScan-${exhibitorid}`);
    if (existing) {
        state.event.data.fieldtemplates.update({ ...existing, template: data });
    } else {
        state.event.data.fieldtemplates.insert({ ...existing,
            entityName: `ExhibitorScan-${exhibitorid}`,
            template: data });
    }
    state.event.data.save();

    return Promise.resolve({
        type: "sendexhibitorscantemplate",
        correlationid: "exhibitorscan-" + exhibitorid,
        payload
    });
}

handlers.sendexhibitorscantemplate = (
    message: ActionQueueMessage<IExhibitorScanTemplateMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventid)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return exhibitorAPI().then((mod) => {
            return mod.setExhibitorScanTemplate(requestmanager, message.payload.exhibitorid, message.payload.template);
        });
    });
};

handlers.exhibitorscan = (
    message: ActionQueueMessage<IExhibitorScanMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return exhibitorScanAPI().then((mod) => {
            return mod.addScan(
                requestmanager,
                message.payload.exhibitorId,
                message.payload.badgeId,
                message.payload.data,
                message.payload.scanDate
            ).then(() => {
                context.syncLeads = true;
            });
        });
    });
};

interface ILeadMetadataMessage {
    eventId: string;
    exhibitorId: string;
    scanId: string;
    data: string;
    scanDate: Date
}

export function sendLeadMetadataUpdate(
    dispatch,
    getState: () => States.IAppState,
    exhibitorId: string,
    scanId: string,
    data: any
): Promise<ActionQueueMessage<ILeadMetadataMessage>> {
    const state = getState();
    const payload = <ILeadMetadataMessage>{
        eventId: state.event.eventid,
        exhibitorId: exhibitorId,
        scanId: scanId,
        data: data,
        scanDate: new Date()
    };

    return Promise.resolve({
        type: "updatescanmetadata",
        payload: payload
    });
}

handlers.updatescanmetadata = (
    message: ActionQueueMessage<ILeadMetadataMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return exhibitorScanAPI().then((mod) => {
            return mod.updateScan(
                requestmanager,
                message.payload.exhibitorId,
                message.payload.scanId,
                message.payload.data
            ).then(() => {
                context.syncLeads = true;
            });
        });
    });
};

export const exhibitorScansActions = {
    sendExhibitorScan: (exhibitorId: string, badgeId: string, data: any) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "sendExhibitorScan", "add")
        );
        return sendScan(
            dispatch, getState, exhibitorId, badgeId, data
        ).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    updateExhibitorLeadMetadata: (
        exhibitorId: string,
        scanId: string,
        data: any
    ) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "updateLeadMetadata", "add")
        );
        return sendLeadMetadataUpdate(
            dispatch, getState, exhibitorId, scanId, data
        ).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    sendScan: (
        badgeId: string,
        data: any
    ) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "sendScan", "add")
        );
        return sendScan(
            dispatch, getState, null, badgeId, data
        ).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    updateLeadMetadata: (
        scanId: string,
        data: any
    ) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "updateLeadMetadata", "add")
        );
        return sendLeadMetadataUpdate(
            dispatch, getState, null, scanId, data
        ).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    setExhibitorScanTemplate: (
        eventid: string,
        exhibitorId: string,
        data: any
    ) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "setExhibitorScanTemplate", "add")
        );
        return setExhibitorScanTemplate(
            dispatch, getState, eventid, exhibitorId, data
        ).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    }
};
