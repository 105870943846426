import type { ActionQueueMessage } from '@inwink/actionqueue';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';

export const handlers:any = {};

function notifAPI() {
    return import("@@event/api/notification");
}
interface IDismissNotificationMessage {
    eventId: string;
    notificationId?: string;
    notificationIds?: string[];
}

handlers.dismissnotification = function dismissnotification(
    message: ActionQueueMessage<IDismissNotificationMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return notifAPI().then((mod) => mod.dismissNotification(requestmanager, message.payload.notificationId)).then(() => {
            context.syncUser = true;
        });
    });
};

handlers.dismissnotifications = function dismissnotifications(
    message: ActionQueueMessage<IDismissNotificationMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return notifAPI().then((mod) => mod.dismissNotifications(requestmanager, message.payload.notificationIds)).then(() => {
            context.syncUser = true;
        });
    });
};
