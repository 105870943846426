import type { IFileInputManager, IBlobType } from '@inwink/fileutils/fileapi';
import type { ICommunityRequests, IEventRequests } from '../services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from './index';
import { Entities } from '@inwink/entities/entities';

export type { IFileInputManager, IBlobType } from '@inwink/fileutils/fileapi';

interface IProvider {
    postJson: (url: string, data?: any, options?: any) => Promise<any>,
    getJson: (url: string, options?: any) => Promise<any>,
}

export function communityFileInputManager(requests: ICommunityRequests) {
    const result = buildFileInputManager({
        postJson: (url, data, headers) => {
            return requests.apiFront.postJson("community/" + requests.apiFront.communityId + "/" + url, data, headers);
        },
        getJson: (url) => {
            return requests.apiFront.getJson("community/" + requests.apiFront.communityId + "/" + url);
        }
    });

    result.getIOSVideoUrl = (streamingUrl: string, token: string, communityId: string) => {
        let targetUrl = "video/manifest";

        if (inwink.config.authMode === "firstpartycookies") {
            targetUrl = `video/community/${communityId}/manifestv2`;
        }
        return requests.apiFront.getJson(`${targetUrl}?baseManifestUrl=${streamingUrl}&token=${token}`);
    };

    const coid = requests.communityid;
    result.getDashVideoAccessUrl = (entity: string, field: string, entityId: string) => {
        const dashUrl = `community/${coid}/file/video/${entity}/${entityId}/${field}/master.mpd`;
        return Promise.resolve(requests.apiFront.getUrl(dashUrl));
    };

    result.getHlsVideoAccessUrl = (entity: string, field: string, entityId: string) => {
        const hlsUrl = `community/${coid}/file/video/${entity}/${entityId}/${field}/master.m3u8`;
        return Promise.resolve(hlsUrl);
    };

    result.getRequestManifestHeaders = () => {
        if (requests.userActions.getToken) {
            return {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "Authorization": () => {
                    return requests.userActions.getToken().then((token) => {
                        return `Bearer ${token}`;
                    });
                }
            };
        }

        return {};
    };

    return result;
}

export function eventFileInputManager(requests: IEventRequests) {
    const result = buildFileInputManager({
        postJson: (url, data, headers) => {
            return requests.apiFront.postEventJson(url, data, headers);
        },
        getJson: (url) => {
            return requests.apiFront.getEventJson(url);
        }
    });

    result.getIOSVideoUrl = (streamingUrl: string, token: string, eventId: string) => {
        let targetUrl = "video/manifest";

        if (inwink.config.authMode === "firstpartycookies") {
            targetUrl = `video/${eventId}/manifestv2`;
        }
        return requests.apiFront.getJson(`${targetUrl}?baseManifestUrl=${streamingUrl}&token=${token}`);
    };

    result.getDashVideoAccessUrl = (entity: string, field: string, entityId: string) => {
        const dashUrl = `${requests.eventid}/file/video/${entity}/${entityId}/${field}/master.mpd`;
        return Promise.resolve(requests.apiFront.getUrl(dashUrl));
    };

    result.getHlsVideoAccessUrl = (entity: string, field: string, entityId: string) => {
        const hlsUrl = `${requests.eventid}/file/video/${entity}/${entityId}/${field}/master.m3u8`;
        return Promise.resolve(requests.apiFront.getUrl(hlsUrl));
    };

    result.getRequestManifestHeaders = () => {
        if (requests.userActions.getToken) {
            return {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "Authorization": () => {
                    return requests.userActions.getToken().then((token) => {
                        return `Bearer ${token}`;
                    });
                }
            };
        }

        return {};
    };

    return result;
}

export function buildFileInputManager(providers: IProvider) {
    const res: IFileInputManager = {
        createTmpFile(model: {
            entity: string,
            field: string,
            type: string,
            name: string,
            blobType?: IBlobType
        }) {
            return createTmpFile(providers, model);
        },

        renewTmpAccessFile(model: {
            blobName: string,
            entity: string,
            field: string,
            type: string,
            name: string
        }) {
            return renewTmpAccessFile(providers, model);
        },

        tryRenewTmpAccessFile(model: {
            sas: string,
            blobName: string,
            entity: string,
            field: string,
            type: string,
            name: string
        }) {
            return tryRenewTmpAccessFile(providers, model);
        },

        getFileAccess(entity: string, field: string, entityId: string) {
            return getFileAccess(providers, entity, field, entityId);
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        copyToTmp(eventId: string, entityName: string, entityId: string, fieldKey: string) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ message: "not supported" });
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getIOSVideoUrl(streamingUrl: string, token: string, eventId: string) {
            // TODO Community
            // return getIOSVideoUrl(eventId, requests as IEventRequests, streamingUrl, token);
            return Promise.reject(new Error("not implemented"));
        },

        finalizeTmpFile(entity: string, blobModel: {
            sas?: string;
            blobName?: string;
        }) {
            return finalizeTmpFile(providers, blobModel);
        }
    };

    return res;
}

export function createTmpFile(
    providers: IProvider,
    model: {
        entity: string,
        field: string,
        type: string,
        name: string,
        blobType?: IBlobType
    },
    context?: any
): Promise<{ url: string, container: string, blobName: string, sas: string }> {
    const contextParam = context || {};
    return providers.postJson.call(contextParam, `file`, JSON.stringify(model), defaultPostHeaderForJsonData);
}

export function renewTmpAccessFile(providers: IProvider, model: {
    blobName: string,
    entity: string,
    field: string,
    type: string,
    name: string
}): Promise<{ url: string, container: string, blobName: string, sas: string }> {
    return providers.postJson(`file/renew`, JSON.stringify(model), defaultPostHeaderForJsonData);
}

export function tryRenewTmpAccessFile(providers: IProvider, model: {
    sas: string,
    blobName: string,
    entity: string,
    field: string,
    type: string,
    name: string
}): Promise<{ url: string, container: string, blobName: string, sas: string }> {
    return providers.postJson(`file/try-renew`, JSON.stringify(model),
        defaultPostHeaderForJsonData)
        .then((result) => {
            return result.status === 204 ? null : result;
        }, (error) => {
            return Promise.reject(error);
        });
}

export function getFileAccess(providers: IProvider, entity: string, field: string, entityId: string) {
    return providers.getJson(`file/${entity}/${entityId}/${field}`, null);
}

export function getIOSVideoUrl(eventId: string, eventRequests: IEventRequests,
    streamingUrl: string, token: any): Promise<{ url: string }> {
    let targetUrl = "video/manifest";

    if (inwink.config.authMode === "firstpartycookies") {
        targetUrl = `video/${eventId}/manifestv2`;
    }
    return eventRequests.apiFront.getJson(`${targetUrl}?baseManifestUrl=${streamingUrl}&token=${token}`);
}

export function getFileExportAccess(providers: IProvider, eventId: string, fileName: string): Promise<{
    url: string;
    sas: string;
}> {
    return providers.getJson(`${eventId}/file/exportaccess?fileName=${fileName}`, null);
}

export function finalizeTmpFile(
    providers: IProvider,
    blobModel: {
        sas?: string;
        blobName?: string;
    },
    context?: any): Promise<void> {
    const contextParam = context || {};
    return providers.postJson.call(contextParam, `file/finalize-tmp`, JSON.stringify(blobModel), defaultPostHeaderForJsonData)
        .then((result) => {
            if (result.status === 200) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        }, (error) => {
            return Promise.reject(error);
        });
}

export function getDisplaySize(size: number, i18nHelper: Entities.i18nHelper, decimals: number) {
    if (size == null || size <= 0) return null;
    const suffixes = i18nHelper.translate("inwinkfile.displaysizes", null, "B|KB|MB|GB|TB|PB|EB|ZB|YB").split("|");
    const i = Math.floor((Math.log(size) / Math.log(1024)));
    let suffixe = '';
    if (i < suffixes.length) {
        suffixe = suffixes[i];
    }
    return `${(size! / Math.pow(1024, i)).toFixed(decimals)} ${suffixe}`.trim();
}