import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '../../../api/front';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function eventMessageDatasourceV3(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IEventMessage>(requestManager, "eventmessage", "EventMessage");
    });
}

export function addEventMessageToFavorite(eventRequests: IEventRequests, eventMessageId: string) {
    return eventRequests.apiFront.postEventJson(`eventmessage/${eventMessageId}/add-favorite`);
}

export function removeEventMessageFromFavorite(eventRequests: IEventRequests, eventMessageId: string) {
    return eventRequests.apiFront.postEventJson(`eventmessage/${eventMessageId}/remove-favorite`);
}

export function addEventMessage(eventRequests: IEventRequests, message) {
    return eventRequests.apiFront.postEventJson(`eventmessage`,
        JSON.stringify({ entity: message }), defaultPostHeaderForJsonData);
}
