import type { States } from '@@services/services';
import { actionQueue } from '@@actionsqueue/index';
import * as Favorites from './actionqueue.favoris';
import * as Person from './actionqueue.person';

function tracking() {
    return import("@@services/tracking/actions");
}

export const actions = {
    addToFavorites: (entityId: string, entityKind: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favorites', 'add', entityKind + ':' + entityId)
        );
        return Favorites.addToFavorites(dispatch, getState, entityId, entityKind)
            .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    removeFromFavorites: (entityId: string, entityKind: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favorites', 'remove', entityKind + ':' + entityId)
        );
        return Favorites.removeFromFavorites(dispatch, getState, entityId, entityKind)
            .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    // dismissNotification: (notificationIds: string[]) => (dispatch, getState: () => States.IAppState) => {
    //     return Notifications.dismissNotification(dispatch, getState, notificationIds);
    // },

    removeFromRecommandations: (targetUserId: string) => (dispatch, getState: () => States.IAppState) => {
        return Person.removeFromRecommandations(dispatch, getState, targetUserId)
            .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    // allowNetworking: (allowNetworking: boolean) => (dispatch, getState: () => States.IAppState) => {
    //     tracker.trackAction(dispatch, getState, 'profile', 'allownetworking', allowNetworking.toString());
    //     return Person.allowNetworking(dispatch, getState, allowNetworking)
    //         .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    // },

    // networkingPreferences: (prefs: any) => (dispatch, getState: () => States.IAppState) => {
    //     return Person.networkingPreferences(dispatch, getState, prefs);
    // },

    notificationsPreferences: (
        allowMailAlerts: boolean,
        allowNotifications: boolean,
        notifyOnContactRequest: boolean,
        notifyOnMessage: boolean,
        allowMailWhenInvitedHasAttended : boolean,
        allowMailWhenInvitedIsRegistered
    ) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'profile', 'notificationspreferences')
        );
        return Person.notificationsPreferences(
            dispatch,
            getState,
            allowMailAlerts,
            allowNotifications,
            notifyOnContactRequest,
            notifyOnMessage,
            allowMailWhenInvitedHasAttended,
            allowMailWhenInvitedIsRegistered
        ).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    // profileInfos: (personInfos: any) => (dispatch, getState: () => States.IAppState) => {
    //     tracker.trackAction(dispatch, getState, 'profile', 'infos');
    //     return Person.profileInfos(dispatch, getState, personInfos)
    //         .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    // },

    registerDevice: (
        uniqueid: string,
        devicetype: string,
        channel: string,
        date: string | Date
    ) => (dispatch, getState: () => States.IAppState) => {
        return Person.registerDevice(dispatch, getState, uniqueid, devicetype, channel, date)
            .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    checkActionQueue() {
        return (dispatch, getState: () => States.IAppState) => {
            actionQueue.checkQueue(dispatch, getState);
        };
    }
};
