import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '../../../api/front';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function surveyAnswerDatasource(requestManager: IEventRequestManager, surveyId: string) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.ISurveyAnswer>(requestManager, `survey/${surveyId}/answer`, "SurveyAnswer");
    });
}

export function addSurveyAnswer(eventRequests: IEventRequests, surveyId: string, answr, answerDate,
    relatedToKind: string, relatedTo: string) {
    const answer = answr;
    answer.creationDate = answerDate;
    answer.relatedTo = relatedTo;
    answer.relatedKind = relatedToKind;

    return eventRequests.apiFront.postEventJson(`survey/${surveyId}/answer`,
        JSON.stringify(answer), defaultPostHeaderForJsonData);
}
